import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";


//Get current estate due
export const getEstateDue = async (userId, setEstateDue, setLoading,setIsLoggedin, navigate) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));
    

    setEstateDue(data);
    setLoading(false);
  } catch (error) {
    console.log(error.response);
  }
};

export const getEstateDues = async (setLoading, setEstateDues, userId) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_DUE(userId));

    setEstateDues(data);
  } catch (error) {
    console.log(error.response);
  } finally {
    setLoading(false);
  }
};

export const getEvent = async (userId, setLoading, setEvents) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_EVENT(userId));

    setEvents(data.events);
    setLoading(false);
  } catch (error) {
    console.log(error.response);
    setLoading(false);
  }
};

export const getManagerAnnouncement = async (
  setLoading,
  userId,
  setAnnouncement
) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_MANAGER_ANNOUNCEMENT(userId));

    setAnnouncement(data?.announcements);
  } catch (error) {
    console.log(error.response);
  } finally {
    setLoading(false);
  }
};
export const getBanners = async (setLoading, userId, setBanners) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_BANNER(userId));
    setBanners(data?.banners);
  } catch (error) {
    console.log(error, "www");
  } finally {
    setLoading(false);
  }
};

export const deleteGuest = async (userId, guestId, onClose) => {
  try {
    const {
      data: { result },
    } = await http.delete(AUTH_ROUTES.DELETE_GUEST(userId, guestId));

    successNotifier(result.message);
    onClose();
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response);
  }
};
