// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalBody,
//   useDisclosure,
//   Button,
//   Text,
//   useColorModeValue,
//   Heading,
//   ModalHeader,
//   ModalCloseButton,
//   Stack,
//   HStack,
// } from "@chakra-ui/react";
// import { COLORS } from "../../../constants/colors";
// import { useContext, useEffect, useState } from "react";
// import { UserProfileContext } from "../../../context/userProfileContext";
// import { AuthContext } from "../../../context/authContext";
// import { useNavigate } from "react-router-dom";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import {
//   getEstateDue,
//   getEstateInfo,
//   initializePayment,
//   verifyEstateDuePayment,
// } from "../../estateDue/service";
// import { infoNotifier } from "../../../components/NotificationHandler";

// export const ConfirmPaymentModal = ({ dueTypes }) => {
//   const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
//   const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const btnBg = useColorModeValue(COLORS.lightGrey, COLORS.green);
//   const btnColor = useColorModeValue(COLORS.green, COLORS.white);
//   const [isLoading, setIsLoading] = useState(false);
//   const { userProfile } = useContext(UserProfileContext);
//   const { tenantName, email } = userProfile;
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const [estateDue, setEstateDue] = useState([]);
//   const [estateInfo, setEstateInfo] = useState([]);
//   const [monthsAhead, setMonthsAhead] = useState(1);
//   const [paymentData, setPaymentData] = useState({});
//   const [paymentModal, setPaymentModal] = useState(false);
//   const [transactionCharge, setTransactionCharge] = useState(0);
//   const [success] = useState(false);
//   const navigate = useNavigate();

//   const { MY_WALLET } = PROTECTED_PATHS;

//   const getEstateDueAmount = estateInfo?.estateDue * monthsAhead;

//   useEffect(() => {
//     // const rate = 0.015;
//     const fixedCharge = 100;
//     const charges = 0.015 * getEstateDueAmount;
//     if (getEstateDueAmount < 2000) {
//       setTransactionCharge(charges);
//       return;
//     } else if (charges >= 2000) {
//       setTransactionCharge(2000 + fixedCharge);
//       return;
//     } else {
//       setTransactionCharge(charges + fixedCharge);
//     }
//   }, [getEstateDueAmount]);

//   useEffect(() => {
//     getEstateDue(userId, setIsLoading, setEstateDue);
//     getEstateInfo(userId, setIsLoading, setEstateInfo);
//   }, [userId]);

//   console.log(dueTypes, "showId");

//   const payEstateDue = () => {
//     const payload = {
//       amount: getEstateDueAmount + transactionCharge,
//       tenantName,
//       email,
//       userId,
//       duration: monthsAhead,
//       dueTypeId: dueTypes?._id,
//     };

//     console.log(payload, "payment payload");

//     if (!payload.email) {
//       infoNotifier("please set your profile and add your Email first ");
//       return;
//     }

//     initializePayment(payload, setIsLoading, setPaymentData, setPaymentModal);
//   };

//   const verifyPayments = () => {
//     const payload = { userId, reference: paymentData?.reference };

//     verifyEstateDuePayment(payload, navigate);
//   };

//   const { isOpen, onOpen, onClose } = useDisclosure();

//   console.log(paymentData?.authorization_url, "urlll");

//   return (
//     <>
//       <Button
//         bg={btnBg}
//         color={btnColor}
//         onClick={onOpen}
//         _hover={{ backgroundColor: `${btnBg}` }}
//       >
//         Pay Now
//       </Button>
//       <Modal
//         size="sm"
//         isOpen={isOpen}
//         onClose={onClose}
//         trapFocus={false} // Prevents the issue with focus on hidden elements
//         blockScrollOnMount={false}
//       >
//         <ModalOverlay />
//         <ModalContent py="50px" bg="black">
//           <ModalBody>
//             <Heading fontSize="20px" color={cardBg} pb="30px">
//               Choose Payment Method
//             </Heading>
//             <Text color={COLORS.lightGrey}>
//               Kindly select your preferred payment method. You'll be receiving
//               an acknoweledgement via SMS
//             </Text>
//             <Button
//               w={"100%"}
//               mt="50px"
//               bg={bgColor}
//               color={cardBg}
//               _hover={{ backgroundColor: `${bgColor}` }}
//               isLoading={isLoading}
//               onClick={payEstateDue}
//             >
//               Pay with checkout
//             </Button>
//             <Modal
//               isOpen={paymentModal}
//               onClose={verifyPayments}
//               isCentered
//               bg="red"
//               size="sm"
//               trapFocus={false} // Prevents the issue with focus on hidden elements
//             >
//               <ModalOverlay />
//               <ModalContent bg={"white"}>
//                 <ModalHeader>Payment checkout</ModalHeader>
//                 <ModalCloseButton />
//                 <Stack pb="4" pt="2" pl="7%" fontWeight="500">
//                   <HStack fontWeight={600} fontSize="12px">
//                     <Text pr="2">
//                       Estate Due: &#8358; {getEstateDueAmount?.toLocaleString()}
//                     </Text>
//                     <Text>
//                       Paystack Charge: &#8358;{" "}
//                       {transactionCharge?.toLocaleString()}{" "}
//                     </Text>
//                   </HStack>
//                   <Text fontWeight={600} fontSize="12px">
//                     Total: &#8358;{" "}
//                     {(transactionCharge + getEstateDueAmount)?.toLocaleString()}
//                   </Text>
//                 </Stack>
//                 <ModalBody p="0">
//                   <iframe
//                     src={paymentData?.authorization_url}
//                     width="100%"
//                     height="450"
//                     title="payment checkout"
//                   ></iframe>
//                 </ModalBody>
//               </ModalContent>
//             </Modal>
//             <Button
//               w={"100%"}
//               mt="20px"
//               border={`1px solid ${cardBg}`}
//               color={cardBg}
//             >
//               Pay with bank transfer
//             </Button>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// };

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  useColorModeValue,
  Heading,
  ModalHeader,
  ModalCloseButton,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";
import { useContext, useEffect, useState } from "react";
import { UserProfileContext } from "../../../context/userProfileContext";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import {
  getEstateDue,
  getEstateInfo,
  initializePayment,
  verifyEstateDuePayment,
} from "../../estateDue/service";
import { infoNotifier } from "../../../components/NotificationHandler";

export const ConfirmPaymentModal = ({ dueTypes }) => {
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const btnBg = useColorModeValue(COLORS.lightGrey, COLORS.green);
  const btnColor = useColorModeValue(COLORS.green, COLORS.white);
  const [isLoading, setIsLoading] = useState(false);
  const { userProfile } = useContext(UserProfileContext);
  const { tenantName, email } = userProfile;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [estateDue, setEstateDue] = useState([]);
  const [estateInfo, setEstateInfo] = useState([]);
  const [monthsAhead, setMonthsAhead] = useState(1);
  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [transactionCharge, setTransactionCharge] = useState(0);
  const navigate = useNavigate();

  const { MY_WALLET } = PROTECTED_PATHS;

  // const getEstateDueAmount = estateInfo?.estateDue * monthsAhead;

  useEffect(() => {
    const fixedCharge = 100;
    const charges = 0.015 * dueTypes?.amount;
    if (dueTypes?.amount < 2000) {
      setTransactionCharge(charges);
      return;
    } else if (charges >= 2000) {
      setTransactionCharge(2000 + fixedCharge);
      return;
    } else {
      setTransactionCharge(charges + fixedCharge);
    }
  }, [dueTypes?.amount]);

  useEffect(() => {
    getEstateDue(userId, setIsLoading, setEstateDue);
    getEstateInfo(userId, setIsLoading, setEstateInfo);
  }, [userId]);

  const payEstateDue = () => {
    const payload = {
      amount: dueTypes?.amount + transactionCharge,
      tenantName,
      email,
      userId,
      duration: monthsAhead,
      dueTypeId: dueTypes?._id,
    };

    console.log(payload, "payload")
    console.log(dueTypes, "duetypess")

    if (!payload.email) {
      infoNotifier("please set your profile and add your Email first ");
      return;
    }

    initializePayment(payload, setIsLoading, setPaymentData, setPaymentModal);
    // Call verifyPayments after initializing payment
    setPaymentModal(true); // Open modal here
  };

  const verifyPayments = () => {
    const payload = { userId, reference: paymentData?.reference, type: "paystack" };

    verifyEstateDuePayment(payload, navigate);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        bg={btnBg}
        color={btnColor}
        onClick={onOpen}
        _hover={{ backgroundColor: `${btnBg}` }}
      >
        Pay Now
      </Button>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={onClose}
        trapFocus={false}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent py="50px" bg="black">
          <ModalBody>
            <Heading fontSize="20px" color={cardBg} pb="30px">
              Choose Payment Method
            </Heading>
            <Text color={COLORS.lightGrey}>
              Kindly select your preferred payment method. You'll be receiving
              an acknowledgement via Email/SMS.
            </Text>
            <Button
              w={"100%"}
              mt="50px"
              bg={bgColor}
              color={cardBg}
              _hover={{ backgroundColor: `${bgColor}` }}
              isLoading={isLoading}
              onClick={payEstateDue}
            >
              Pay with Paystack
            </Button>
            <Modal
              isOpen={paymentModal}
              onClose={onClose} // Close modal without verification
              isCentered
              bg="red"
              size="sm"
              trapFocus={false}
            >
              <ModalOverlay />
              <ModalContent bg={"white"}>
                <ModalHeader>Payment checkout</ModalHeader>
                <ModalCloseButton onClick={verifyPayments} />{" "}
                {/* Verify payment on close */}
                <Stack pb="4" pt="2" pl="7%" fontWeight="500">
                  <HStack fontWeight={600} fontSize="12px">
                    <Text pr="2">
                      {dueTypes?.type}: &#8358; {dueTypes?.amount?.toLocaleString()}
                    </Text>
                    <Text>
                      Paystack Charge: &#8358;{" "}
                      {transactionCharge?.toLocaleString()}{" "}
                    </Text>
                  </HStack>
                  <Text fontWeight={600} fontSize="12px">
                    Total: &#8358;{" "}
                    {(transactionCharge + dueTypes?.amount)?.toLocaleString()}
                  </Text>
                </Stack>
                <ModalBody p="0">
                  <iframe
                    src={paymentData?.authorization_url}
                    width="100%"
                    height="450"
                    title="payment checkout"
                  ></iframe>
                </ModalBody>
              </ModalContent>
            </Modal>
            <Button
              w={"100%"}
              mt="20px"
              border={`1px solid ${cardBg}`}
              color={COLORS.primary}
            >
              Pay with bank transfer
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
