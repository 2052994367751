import { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const user = {
      token: localStorage.getItem('11p221#') || '',
      // tenantCode: localStorage.getItem('tenantCode') || '',
      userId: localStorage.getItem('userId') || '',
      // tenantPhone: localStorage.getItem('tenantPhone'),
    };
    setUserInfo(user);
  }, []);

  return <AuthContext.Provider value={{ isLoggedin, setIsLoggedin, userInfo }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
