import { Box, SlideFade, Stack } from "@chakra-ui/react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import HeadSeemore from "../../components/HeadSeemore";
import DebitCard from "./components/DebitCard";
import OtherServices from "./components/OtherServices";
import SingleTrasaction from "./components/SingleTrasaction";
import Footer from "../../components/footer";
import { useContext, useEffect, useState } from "react";
import { getAccount, getAllTransactions, getWallet } from "./service";
import { AuthContext } from "../../context/authContext";
import FullPageLoader from "../../components/FullPageLoader";
import { v4 } from "uuid";
import { Transactions } from "./components/Transactions";
import { PROTECTED_PATHS } from "../../app/constants";
import { getUserProfile } from "../settings/service/getUserProfile";

const MyWallet = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [allTransactions, setAllTransactions] = useState([]);
  const [wallet, setWallet] = useState({});
  const [reload, setReload] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const { ALL_TRANSACTIONS } = PROTECTED_PATHS;

  useEffect(() => {
    getAllTransactions(userId, setLoading, setAllTransactions);
    getWallet(userId, setLoading, setWallet);
    getUserProfile(userId, setLoading, setUserProfile);
  }, [userId, reload]);

  console.log(userProfile, "show", userInfo);

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX="2000px">
      <Box pb="10">
        <Box shadow="xs">
          <Container>
            <Header menu />
          </Container>
        </Box>
        <Container>
          <Stack py="10" spacing="6">
            <DebitCard
              setLoading={setLoading}
              userId={userId}
              wallet={userProfile}
              setReload={() => setReload(!reload)}
              data={allTransactions}
            />
            <HeadSeemore head="Transactions" seemore to={ALL_TRANSACTIONS} />
            <Transactions data={allTransactions} />
          </Stack>
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  );
};

export default MyWallet;
// import { Box, SlideFade, Stack } from '@chakra-ui/react';
// import Container from '../../components/Container';
// import Header from '../../components/Header';
// import HeadSeemore from '../../components/HeadSeemore';
// import DebitCard from './components/DebitCard';
// import OtherServices from './components/OtherServices';
// import SingleTrasaction from './components/SingleTrasaction';
// import Footer from '../../components/footer';
// import { useContext, useEffect, useState } from 'react';
// import { getAllTransactions, getWallet } from './service';
// import { AuthContext } from '../../context/authContext';
// import FullPageLoader from '../../components/FullPageLoader';
// import { v4 } from 'uuid';

// const MyWallet = () => {
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const [loading, setLoading] = useState();
//   const [allTransactions, setAllTransactions] = useState([]);
//   const [wallet, setWallet] = useState({});
//   const [reload, setReload] = useState(false);

//   useEffect(() => {
//     getAllTransactions(userId, setLoading, setAllTransactions);
//     getWallet(userId, setLoading, setWallet);
//   }, [userId, reload]);

//   return loading ? (
//     <FullPageLoader />
//   ) : (
//     <SlideFade in={true} offsetX='2000px'>
//       <Box pb='10'>
//         <Box shadow='xs'>
//           <Container>
//             <Header menu />
//           </Container>
//         </Box>
//         <Container>
//           <Stack py='10' spacing='5'>
//             <DebitCard wallet={wallet} setReload={() => setReload(!reload)} />
//             <HeadSeemore head='Other Services' />
//             <OtherServices />
//             <HeadSeemore head='Transaction' />
//             {allTransactions.slice(0, 5)?.map((transaction) => (
//               <SingleTrasaction key={v4()} {...transaction} />
//             ))}
//           </Stack>
//         </Container>
//         <Footer />
//       </Box>
//     </SlideFade>
//   );
// };

// export default MyWallet;
