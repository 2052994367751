import {
  Box,
  Flex,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
  Center,
  VStack,
  Image,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { COLORS } from "../../../constants/colors";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { transition } from "../../..";
import { PROTECTED_PATHS } from "../../../app/constants";
import { getAllTransactions } from "../service";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

export const AllTransactions = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.white, COLORS.green);
  const TransBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const textColor = useColorModeValue(COLORS.primary, COLORS.white);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [allTransactions, setAllTransactions] = useState([]);
  const [loading, setLoading] = useState();
  const [showButtons, setShowButtons] = useState(true);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const { MY_WALLET } = PROTECTED_PATHS;

  useEffect(() => {
    getAllTransactions(userId, setLoading, setAllTransactions);
  }, [userId]);

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePrint = () => {
    setShowButtons(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Stack pb={"10vh"}>
        <Box shadow="sm">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container>
          {allTransactions && allTransactions.length > 0 ? (
            allTransactions.map((datum) => (
              <Box
                key={datum.id}
                bg={TransBg}
                p="15px"
                rounded={"lg"}
                mt="20px"
                onClick={() =>
                  navigate("/transaction-details", { state: datum })
                }
                _hover={{ cursor: "pointer" }}
              >
                <>
                  <Flex justify="space-between" mb="10px">
                    <Text color={cardBg}>{datum?.services}</Text>
                    <Text color={textColor} 
                    // color={bgColor}
                    >
                      {datum?.status}
                    </Text>
                  </Flex>
                  <Flex justify={"space-between"}>
                    <Text color={COLORS.lightGrey}>
                      {dayjs(datum?.updatedAt).format(" h:mm A MMM D, YYYY")}
                    </Text>
                    <Text color={cardBg}>
                      {" "}
                      &#8358; {datum?.amount?.toLocaleString()}
                    </Text>
                  </Flex>
                </>
              </Box>
            ))
          ) : (
            <Center h="calc(100vh - 150px)">
              <VStack>
                <Image src="/Empty.svg" alt="Empty" />
                <Text as="h2" py="5" fontSize="18px" color={cardBg}>
                  You have no transaction yet
                </Text>
              </VStack>
            </Center>
          )}
        </Container>
      </Stack>
    </SlideFade>
  );
};

// <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="full">
//   <ModalOverlay />
//   <ModalContent
//     bg={cardBg}
//     color={bgColor}
//     // h={"100vh"}
//     textAlign={"center"}
//   >
//     <ModalHeader pt={"30px"}>
//       <Flex justify={"center"} mt={"20px"}>
//         <HStack>
//           <Image src={logo} alt="logo" color={"black"} />
//           <Text color={"#000"} fontWeight={"light"}>
//             MyEstate
//           </Text>
//         </HStack>
//       </Flex>
//     </ModalHeader>
//     <ModalCloseButton />
//     <ModalBody>
//       <Text fontSize={"20px"} pb={"40px"}>
//         Transaction Details
//       </Text>
//       {selectedTransaction && (
//         <Stack spacing={4}>
//           <Flex justify={"space-between"}>
//             <Text>Account Name:</Text>
//             <Text>{selectedTransaction.userId.accountName}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Account Number:</Text>
//             <Text>{selectedTransaction.userId.accountNo}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Bank:</Text>
//             <Text>{selectedTransaction.userId.bank}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Email:</Text>
//             <Text>{selectedTransaction.userId.email}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Phone Number:</Text>
//             <Text>{selectedTransaction.userId.tenantPhone}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Service:</Text>
//             <Text>{selectedTransaction.services}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Transaction Type:</Text>
//             <Text>{selectedTransaction.transactionType}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Transaction Ref:</Text>
//             <Text>{selectedTransaction.transactionRef}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Transaction Channel:</Text>
//             <Text>{selectedTransaction.transactionChannel}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Status:</Text>
//             <Text>{selectedTransaction.status}</Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Date:</Text>
//             <Text>
//               {dayjs(selectedTransaction.updatedAt).format(
//                 " h:mm A MMM D, YYYY"
//               )}
//             </Text>
//           </Flex>
//           <Flex justify={"space-between"}>
//             <Text>Amount:</Text>
//             <Text>&#8358; {selectedTransaction?.amount}</Text>
//           </Flex>
//         </Stack>
//       )}
//     </ModalBody>
//     <ModalFooter mt={"50px"}>
//       <Flex justify={"start"} w={"100%"}>
//         <Text>Powered by MyEstate.ng</Text>
//       </Flex>
//       {showButtons && (
//         <>
//           <Button
//             className="modal-button"
//             variant="outline"
//             borderColor={COLORS.green}
//             color={COLORS.green}
//             mr={3}
//             onClick={handleCloseModal}
//           >
//             Close
//           </Button>
//           <Button
//             // className="modal-button"
//             _hover={{ color: `${cardBg}` }}
//             bg={COLORS.green}
//             color={cardBg}
//             // onClick={() => window.print()}
//             onClick={handlePrint}
//           >
//             Print
//           </Button>
//         </>
//       )}
//     </ModalFooter>
//   </ModalContent>
// </Modal>

// import {
//   Box,
//   Flex,
//   SlideFade,
//   Stack,
//   Text,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import React from "react";
// import { COLORS } from "../../../constants/colors";
// import Container from "../../../components/Container";
// import Header from "../../../components/Header";
// import { transition } from "../../..";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import { useState } from "react";
// import { useEffect } from "react";
// import { getAllTransactions } from "../service";
// import { useContext } from "react";
// import { AuthContext } from "../../../context/authContext";
// import dayjs from "dayjs";

// export const AllTransactions = () => {
//   const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const [allTransactions, setAllTransactions] = useState([]);
//   const [loading, setLoading] = useState();

//   const { MY_WALLET } = PROTECTED_PATHS;

//   useEffect(() => {
//     getAllTransactions(userId, setLoading, setAllTransactions);
//   }, [userId]);
//   return (
//     <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
//       <Stack>
//         <Box shadow="sm">
//           <Container>
//             <Header goto={MY_WALLET} />
//           </Container>
//         </Box>
//         <Container>
//           {allTransactions?.map((datum) => (
//             <Box
//               bg={COLORS.chartbg}
//               color={"red"}
//               p="15px"
//               rounded={"lg"}
//               mt="20px"
//             >
//               <>
//                 <Flex justify="space-between" mb="10px">
//                   <Text color={cardBg}>{datum?.services}</Text>
//                   <Text color={bgColor}>{datum?.status}</Text>
//                 </Flex>
//                 <Flex justify={"space-between"}>
//                   <Text color={COLORS.lightGrey}>
//                     {dayjs(datum?.updatedAt).format(" h:mm A MMM D, YYYY")}
//                   </Text>
//                   <Text color={cardBg}> NGN {datum?.amount}</Text>
//                 </Flex>
//               </>
//             </Box>
//           ))}
//         </Container>
//       </Stack>
//     </SlideFade>
//   );
// };
