import {
  Box,
  Flex,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
  Image,
  Center,
  VStack,
} from "@chakra-ui/react";
import "pure-react-carousel/dist/react-carousel.es.css";
import dayjs from "dayjs";
import { COLORS } from "../../../constants/colors";
import { PROTECTED_PATHS } from "../../../app/constants";
import { transition } from "../../..";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Footer from "../../../components/footer";
import { ConfirmPaymentModal } from "./ConfirmPaymentModal";
import { useContext, useEffect, useState } from "react";
import { getDueTypes } from "../service";
import { AuthContext } from "../../../context/authContext";
import FullPageLoader from "../../../components/FullPageLoader";

const EstateBills = () => {
  const [dueTypes, setDueTypes] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const { userId } = userInfo;
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const TransBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const { MY_WALLET } = PROTECTED_PATHS;

  useEffect(() => {
    getDueTypes(userId, setLoading, setDueTypes);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetY="500px" {...transition}>
      <Stack pb="15vh" w={"100%"} overflowX={"hidden"}>
        <Stack bg={pageBg} my="20px">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Stack>
        <Container>
          {!dueTypes ? (
            <Center h="calc(100vh - 150px)">
              <VStack>
                <Image src="/Empty.svg" alt="Empty" />
                <Text as="h2" py="5" fontSize="18px" color={cardBg}>
                  You have no estate bills
                </Text>
              </VStack>
            </Center>
          ) : (
            <>
              {dueTypes.map((datum, index) => (
                <Box key={index} bg={TransBg} p="15px" rounded="lg" mb="20px">
                  <Flex justify="space-between" mb="20px">
                    <Text color={cardBg}>{datum?.type || "--"}</Text>
                    <Text color={cardBg}>
                      &#8358; {(datum?.amount || "--")?.toLocaleString()}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text color={COLORS.lightGrey}>
                      {dayjs(datum?.updatedAt).format("DD MMM YYYY") || "--"}
                    </Text>
                    <ConfirmPaymentModal dueTypes={datum} />
                  </Flex>
                </Box>
              ))}
            </>
          )}
        </Container>

        <Footer />
      </Stack>
    </SlideFade>
  );
};

export default EstateBills;
