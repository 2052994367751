import { PROTECTED_PATHS } from "../../../app/constants";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { timeout } from "../../../components/utils/timeout";
import http, { AUTH_ROUTES } from "../../../services/api";

// export const initializePayment = async (payload, setIsLoading, setPaymentData, setPaymentModal) => {
//   setIsLoading(true);
//   try {
//     setIsLoading(true);
//     const {
//       data: {
//         result: { data },
//       },
//     } = await http.post(AUTH_ROUTES.INITIALIZE_ESTATE_DUE_PAYMENT, payload);

//     setPaymentData(data);
//     setPaymentModal(true);
//   } catch (error) {
//     errorNotifier(error?.response?.data.errors[0].message);
//   } finally {
//     setIsLoading(false);
//   }
// };

export const initializePayment = async (
  payload,
  setIsLoading,
  setPaymentData,
  setPaymentModal
) => {
  setIsLoading(true);
  try {
    const { data } = await http.post(
      AUTH_ROUTES.INITIALIZE_ESTATE_DUE_PAYMENT,
      payload
    );

    // Extract the authorization_url and reference
    const {
      result: {
        response: {
          data: { authorization_url, reference },
        },
      },
    } = data;

    console.log(authorization_url, "Paystack authorization URL"); // Log the URL to verify
    setPaymentData({
      authorization_url: authorization_url,
      reference: reference,
    });
    setPaymentModal(true);
  } catch (error) {
    errorNotifier(error?.response?.data.errors[0].message);
  } finally {
    setIsLoading(false);
  }
};

export const verifyEstateDuePayment = async (payload, navigate) => {
  const { MY_WALLET } = PROTECTED_PATHS;

  try {
    await http.post(AUTH_ROUTES.VERIFY_PAYMENT, payload);
    successNotifier("Payment Successful");
    setTimeout(() => navigate(MY_WALLET), 2000);
  } catch (e) {
    console.log(e, "verification error")
    errorNotifier(e?.response?.data?.message);
  }
};

export const getEstateDue = async (userId, setIsLoading, setEstateDue) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_DUE(userId));

    setEstateDue(data?.estateDues);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

export const getEstateInfo = async (userId, setIsLoading, setEstateInfo) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));

    setEstateInfo(data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};
