import {
  Box,
  Circle,
  Flex,
  HStack,
  Select,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState } from "react";
import DatePicker from "react-datepicker";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { inviteGuest } from "../service/inviteGuest";
import { daysOfTheWeek } from "./inviteData";
import RandomPicker from "./RandomPicker";
import RandomSummary from "./RandomSummary";
import RangeSummary from "./RangeSummary";
import ShareInviteLink from "./ShareInviteLink";
import ShareModal from "./ShareModal";
import Summary from "./Summary";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import { timeout } from "../../../components/utils/timeout";
import { getDatesWithinDateRange } from "../../../components/utils/getNumberOfWeeksBtwTwoDates";
import { UserProfile } from "../../../context/userProfileContext";

const CustomizeVisitModal = (props) => {
  const today = new Date();
  const navigate = useNavigate();
  const { MY_ESTATE } = PROTECTED_PATHS;
  const [recurrence, setRecurrence] = useState("Weekly");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [days, setDays] = useState([]);
  const [allowCompany, setAllowCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guestCode, setGuestCode] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRandomDates, setSelectedRandomDates] = useState([]);
  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;

  const initialValues = {
    tenantCode,
    userId: _id,
    name: props.name,
    phone: props.phone,
    oneTimeVisit: props.isChecked ? 'no' : 'no',
    allowCompany,
    startDate: dayjs(today).format("YYYY-MM-DD"),
    endDate: dayjs(today).format("YYYY-MM-DD"),
    days: [],
    // recurring: true,
  };

  const [customVisitValues, setCustomVisitValues] = useState(initialValues);

  const handleStartDate = (date) => {
    setStartDate(date);
    const formated = dayjs(date).format("YYYY-MM-DD");
    const formatedDate = formated;

    setCustomVisitValues((values) => ({
      ...values,
      startDate: formatedDate,
    }));
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    const formated = dayjs(date).format("YYYY-MM-DD");
    const formatedDate = formated;

    setCustomVisitValues((values) => ({
      ...values,
      endDate: formatedDate,
    }));
  };

  const handleRepeatOn = (e) => {
    if (days.includes(e.target.innerText)) {
      removeDay(e.target.innerText);
      return;
    }
    setDays([...days, e.target.innerText]);

    setCustomVisitValues((customVisitValues) => ({
      ...customVisitValues,
      recurring: true,
      days: [...days, e.target.innerText],
    }));
  };

  const removeDay = (day) => {
    const remainingSelectedDays = days.filter((d) => d !== day);

    setDays(remainingSelectedDays);
    setCustomVisitValues((customVisitValues) => ({
      ...customVisitValues,
      days: remainingSelectedDays,
    }));
  };

  const handleAllowCompany = () => {
    setAllowCompany(!allowCompany);
    setCustomVisitValues((values) => ({
      ...values,
      allowCompany: !allowCompany,
    }));
  };

  const handleCustomInvite = () => {
    inviteGuest(
      customVisitValues,
      setLoading,
      setGuestCode,
      onOpen,
      onClose,
      navigate
    );
  };

  const handleShareClose = () => {
    onClose();
    timeout(() => navigate(MY_ESTATE), 1000);
  };

  return (
    <>
      <Stack spacing="4">
        <Text fontWeight="600" textAlign="center" fontSize="1.2rem">
          Customize your visit
        </Text>
        <HStack gap="1rem">
          <Select
            size="sm"
            maxW="150px"
            value={recurrence}
            onChange={(e) => setRecurrence(e.target.value)}
          >
            <option>Weekly</option>
            <option>Random dates</option>
            <option>Date Range</option>
          </Select>
        </HStack>
        {recurrence === "Weekly" ? (
          <Box>
            <Text>Repeat on</Text>
            <HStack wrap="wrap">
              {daysOfTheWeek.map((day) => (
                <Circle
                  h="40px"
                  cursor="pointer"
                  w="40px"
                  key={day}
                  name={day}
                  bg={days.includes(day) ? "primaryDark" : "bgColorLight"}
                  color={days.includes(day) ? "#fff" : ""}
                  fontWeight={600}
                  fontSize="12px"
                  _active={{ bg: "lightPink" }}
                  onClick={handleRepeatOn}
                >
                  {day}
                </Circle>
              ))}
            </HStack>
          </Box>
        ) : recurrence === "Random dates" ? (
          <Box>
            <RandomPicker
              setSelectedRandomDates={setSelectedRandomDates}
              setCustomVisitValues={setCustomVisitValues}
            />
          </Box>
        ) : null}

        <Box>
          <Text pr="3" as="small">
            Allow Company
          </Text>
          <Switch isChecked={allowCompany} onChange={handleAllowCompany} />
        </Box>

        {recurrence !== "Random dates" && (
          <Flex justify="space-between" wrap="wrap" py="5">
            <Stack zIndex="10">
              <Text>Start date</Text>
              <DatePicker
                minDate={new Date()}
                dateFormat={"dd/MM/yyyy"}
                selected={startDate}
                onChange={(date) => handleStartDate(date)}
              />
            </Stack>
            <Stack>
              <Text>End date</Text>
              <DatePicker
                minDate={new Date()}
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => handleEndDate(date)}
              />
            </Stack>
          </Flex>
        )}

        <CautionAlertDialog
          onContinue={handleCustomInvite}
          loading={loading}
          button="Send Invite"
          buttonProps={{ bg: "primaryDark" }}
        >
          {recurrence === "Date Range" ? (
            <RangeSummary customVisitValues={customVisitValues} />
          ) : recurrence === "Random dates" ? (
            <RandomSummary
              selectedRandomDates={selectedRandomDates}
              customVisitValues={customVisitValues}
            />
          ) : (
            <Summary
              getDatesWithinDateRange={() =>
                getDatesWithinDateRange(startDate, endDate)
              }
              customVisitValues={customVisitValues}
              name={props.name}
              phone={props.phone}
            />
          )}
        </CautionAlertDialog>
      </Stack>
      <ShareModal isOpen={isOpen} onOpen={onOpen} onClose={handleShareClose}>
        <ShareInviteLink onClose={onClose} guestCode={guestCode} />
      </ShareModal>
    </>
  );
};

export default CustomizeVisitModal;

// import {
//   Box,
//   Circle,
//   Flex,
//   HStack,
//   Select,
//   Stack,
//   Switch,
//   Text,
//   useDisclosure,
//   useColorModeValue,
//   Input,
// } from "@chakra-ui/react";
// import dayjs from "dayjs";
// import { useState } from "react";
// import DatePicker from "react-datepicker";
// import CautionAlertDialog from "../../../components/CautionAlertDialog";
// import { inviteGuest } from "../service/inviteGuest";
// import { daysOfTheWeek } from "./inviteData";
// import RandomPicker from "./RandomPicker";
// import RandomSummary from "./RandomSummary";
// import RangeSummary from "./RangeSummary";
// import ShareInviteLink from "./ShareInviteLink";
// import ShareModal from "./ShareModal";
// import Summary from "./Summary";
// import { useNavigate } from "react-router-dom";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import { timeout } from "../../../components/utils/timeout";
// import { getDatesWithinDateRange } from "../../../components/utils/getNumberOfWeeksBtwTwoDates";
// import { UserProfile } from "../../../context/userProfileContext";
// import { COLORS } from "../../../constants/colors";

// const CustomizeVisitModal = (props) => {
//   const today = new Date();
//   const currentTime = dayjs(today).format("HH:mm");
//   const navigate = useNavigate();
//   const pageBg = useColorModeValue(COLORS.primary, COLORS.darkModeBg);
//   const appTextColor = useColorModeValue(COLORS.green, COLORS.white);
//   // const appTextColor = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const { MY_ESTATE } = PROTECTED_PATHS;
//   const [recurrence, setRecurrence] = useState("Weekly");
//   const [startDate, setStartDate] = useState(today);
//   const [endDate, setEndDate] = useState(today);
//   const [startTime, setStartTime] = useState("00:00");
//   const [endTime, setEndTime] = useState("00:00");
//   const [days, setDays] = useState([]);
//   const [allowCompany, setAllowCompany] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [guestCode, setGuestCode] = useState("");
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [selectedRandomDates, setSelectedRandomDates] = useState([]);
//   const { userProfile } = UserProfile();
//   const { _id, tenantCode } = userProfile;

//   const initialValues = {
//     tenantCode,
//     userId: _id,
//     name: props.name,
//     phone: props.phone,
//     oneTimeVisit: false,
//     allowCompany,
//     startDate: dayjs(today).format("YYYY-MM-DD"),
//     endDate: dayjs(today).format("YYYY-MM-DD"),
//     startTime: currentTime,
//     endTime,
//     days: [],
//     // recurring: true,
//   };

//   const [customVisitValues, setCustomVisitValues] = useState(initialValues);

//   const handleStartDate = (date) => {
//     setStartDate(date);
//     const formated = dayjs(date).format("YYYY-MM-DD");
//     const formatedDate = formated;

//     setCustomVisitValues((values) => ({
//       ...values,
//       startDate: formatedDate,
//     }));
//   };
//   const handleEndDate = (date) => {
//     setEndDate(date);
//     const formated = dayjs(date).format("YYYY-MM-DD");
//     const formatedDate = formated;

//     setCustomVisitValues((values) => ({
//       ...values,
//       endDate: formatedDate,
//     }));
//   };

//   const handleStartTime = (date) => {
//     const time = dayjs(date).format("HH:mm");
//     const startDateTime = dayjs(
//       `${dayjs(startDate).format("YYYY-MM-DD")} ${time}`
//     );
//     const endDateTime = startDateTime.add(3, "hours");

//     setStartTime(time);
//     setEndTime(endDateTime.format("HH:mm"));
//     setCustomVisitValues((values) => ({
//       ...values,
//       startTime: time,
//       endTime: endDateTime.format("HH:mm"),
//     }));

//     // Ensure end date is 3 hours after start date
//     const newEndDate = endDateTime.toDate();
//     if (!dayjs(endDate).isSame(newEndDate, "day")) {
//       setEndDate(newEndDate);
//       setCustomVisitValues((values) => ({
//         ...values,
//         endDate: dayjs(newEndDate).format("YYYY-MM-DD"),
//       }));
//     }
//   };

//   const handleEndTime = (date) => {
//     const time = dayjs(date).format("HH:mm");
//     const endDateTime = dayjs(
//       `${dayjs(startDate).format("YYYY-MM-DD")} ${time}`
//     );
//     const startDateTime = dayjs(
//       `${dayjs(startDate).format("YYYY-MM-DD")} ${startTime}`
//     );

//     // Ensure end time is at least 3 hours after start time
//     const hoursDifference = endDateTime.diff(startDateTime, "hours");
//     if (hoursDifference < 3) {
//       const newEndTime = startDateTime.add(3, "hours").format("HH:mm");
//       setEndTime(newEndTime);
//       setCustomVisitValues((values) => ({
//         ...values,
//         endTime: newEndTime,
//       }));
//     } else if (hoursDifference > 3) {
//       // Update start time to be 3 hours before end time
//       const newStartTime = endDateTime.subtract(3, "hours").format("HH:mm");
//       setStartTime(newStartTime);
//       setEndTime(time);
//       setCustomVisitValues((values) => ({
//         ...values,
//         startTime: newStartTime,
//         endTime: time,
//       }));
//     } else {
//       // Update end time only
//       setEndTime(time);
//       setCustomVisitValues((values) => ({
//         ...values,
//         endTime: time,
//       }));
//     }
//   };

//   const handleRepeatOn = (e) => {
//     if (days.includes(e.target.innerText)) {
//       removeDay(e.target.innerText);
//       return;
//     }
//     setDays([...days, e.target.innerText]);

//     setCustomVisitValues((customVisitValues) => ({
//       ...customVisitValues,
//       recurring: true,
//       days: [...days, e.target.innerText],
//     }));
//   };

//   const removeDay = (day) => {
//     const remainingSelectedDays = days.filter((d) => d !== day);

//     setDays(remainingSelectedDays);
//     setCustomVisitValues((customVisitValues) => ({
//       ...customVisitValues,
//       days: remainingSelectedDays,
//     }));
//   };

//   const handleAllowCompany = () => {
//     setAllowCompany(!allowCompany);
//     setCustomVisitValues((values) => ({
//       ...values,
//       allowCompany: !allowCompany,
//     }));
//   };

//   const handleCustomInvite = () => {
//     inviteGuest(
//       customVisitValues,
//       setLoading,
//       setGuestCode,
//       onOpen,
//       onClose,
//       navigate
//     );
//   };

//   const handleShareClose = () => {
//     onClose();
//     timeout(() => navigate(MY_ESTATE), 1000);
//   };

//   return (
//     <>
//       <Stack spacing="4" color={appTextColor}>
//         <Text fontWeight="600" textAlign="center" fontSize="1.2rem">
//           Customize your visit
//         </Text>
//         <HStack gap="1rem">
//           <Select
//             size="sm"
//             maxW="150px"
//             value={recurrence}
//             onChange={(e) => setRecurrence(e.target.value)}
//           >
//             <option>Weekly</option>
//             <option>Random dates</option>
//             <option>Date Range</option>
//           </Select>
//         </HStack>
//         {recurrence === "Weekly" ? (
//           <Box>
//             <Text>Repeat on</Text>
//             <HStack wrap="wrap">
//               {daysOfTheWeek.map((day) => (
//                 <Circle
//                   h="40px"
//                   cursor="pointer"
//                   w="40px"
//                   key={day}
//                   name={day}
//                   bg={days.includes(day) ? "primaryDark" : "bgColorLight"}
//                   color={days.includes(day) ? COLORS.primary : COLORS.primary}
//                   fontWeight={600}
//                   fontSize="12px"
//                   _active={{ bg: "lightPink" }}
//                   onClick={handleRepeatOn}
//                 >
//                   {day}
//                 </Circle>
//               ))}
//             </HStack>
//           </Box>
//         ) : recurrence === "Random dates" ? (
//           <Box>
//             <RandomPicker
//               setSelectedRandomDates={setSelectedRandomDates}
//               setCustomVisitValues={setCustomVisitValues}
//             />
//           </Box>
//         ) : null}

//         <Box>
//           <Text pr="3" as="small">
//             Allow Company
//           </Text>
//           <Switch
//             colorScheme="white"
//             isChecked={allowCompany}
//             onChange={handleAllowCompany}
//           />
//         </Box>

//         {recurrence !== "Random dates" && (
//           <>
//             <Flex justify="space-between" py="5" width="100%" gap="1rem">
//               <Stack zIndex="10">
//                 <Text>Start date</Text>
//                 <DatePicker
//                   minDate={new Date()}
//                   dateFormat={"dd/MM/yyyy"}
//                   selected={startDate}
//                   onChange={(date) => handleStartDate(date)}
//                 />
//               </Stack>
//               <Stack>
//                 <Text>End date</Text>
//                 <DatePicker
//                   minDate={new Date()}
//                   selected={endDate}
//                   dateFormat="dd/MM/yyyy"
//                   onChange={(date) => handleEndDate(date)}
//                 />
//               </Stack>
//             </Flex>
//             <Flex
//               justify="space-between"
//               gap="1rem"
//               width="100%"
//               direction="row"
//             >
//               <Stack flex="1" zIndex="10">
//                 <Text>Start Time</Text>
//                 <DatePicker
//                   selected={dayjs(
//                     `${dayjs(startDate).format("YYYY-MM-DD")} ${startTime}`
//                   ).toDate()}
//                   onChange={(date) => handleStartTime(date)}
//                   showTimeSelect
//                   showTimeSelectOnly
//                   // timeIntervals={15}
//                   timeCaption="Time"
//                   dateFormat="h:mm aa"
//                 />
//               </Stack>
//               <Stack flex="1">
//                 <Text>End Time</Text>
//                 <DatePicker
//                   selected={dayjs(
//                     `${dayjs(startDate).format("YYYY-MM-DD")} ${endTime}`
//                   ).toDate()}
//                   onChange={(date) => handleEndTime(date)}
//                   showTimeSelect
//                   showTimeSelectOnly
//                   // timeIntervals={15}
//                   timeCaption="Time"
//                   dateFormat="h:mm aa"
//                 />
//               </Stack>
//             </Flex>
//           </>
//         )}

//         <CautionAlertDialog
//           onContinue={handleCustomInvite}
//           loading={loading}
//           button="Send Invite"
//           buttonProps={{ bg: "primaryDark" }}
//         >
//           {recurrence === "Date Range" ? (
//             <RangeSummary customVisitValues={customVisitValues} />
//           ) : recurrence === "Random dates" ? (
//             <RandomSummary
//               selectedRandomDates={selectedRandomDates}
//               customVisitValues={customVisitValues}
//             />
//           ) : (
//             <Summary
//               getDatesWithinDateRange={() =>
//                 getDatesWithinDateRange(startDate, endDate)
//               }
//               customVisitValues={customVisitValues}
//               name={props.name}
//               phone={props.phone}
//             />
//           )}
//         </CautionAlertDialog>
//       </Stack>
//       <ShareModal isOpen={isOpen} onOpen={onOpen} onClose={handleShareClose}>
//         <ShareInviteLink onClose={onClose} guestCode={guestCode} />
//       </ShareModal>
//     </>
//   );
// };

// export default CustomizeVisitModal;
