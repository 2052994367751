import axios from "axios";

export * from "./routes.constants";

//prod
// export const baseURL = "https://backend.myestate.ng/api/v1";

//aahera database
export const baseURL = "https://api.aahera.com.ng/api/v1";

const axiosInstance = axios.create({
  baseURL,
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json ",
    "Access-Control-Allow-Headers": "Content-Type",
  },
});

const addTokenToRequest = async (req) => {
  const token = localStorage.getItem("11p221#");
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;
