import { PROTECTED_PATHS } from "../../app/constants";

export const getComponentTitle = (pathname) => {
  switch (pathname) {
    case PROTECTED_PATHS.MARKET_PLACE:
      return "Market Place";

    case PROTECTED_PATHS.ALL_CATEGORY:
      return "Category List";

    case PROTECTED_PATHS.NEWS_EVENTS:
      return "News/Events";

    case PROTECTED_PATHS.MY_WALLET:
      return "Bills And Payment";

    case PROTECTED_PATHS.GUEST_LIST:
      return "Guest Log";

    case PROTECTED_PATHS.ESTATE_DUE:
      return "Estate Due";

    case PROTECTED_PATHS.ISSUE_REPORT || PROTECTED_PATHS.ONE_ISSUE_REPORT:
      return "Issue Report";

    case PROTECTED_PATHS.MY_ESTATE:
      return "Dashboard";

    case PROTECTED_PATHS.ISSUE_REPORTS_HISTORY:
      return "Issue Report history";

    case PROTECTED_PATHS.MY_ERRANDS:
      return "Errands";
    case PROTECTED_PATHS.ERRAND_HOME:
      return "Errands";

    case PROTECTED_PATHS.SELECTED_ERRANDS:
      return "Select Errand";

    case PROTECTED_PATHS.POST_ADVERT:
      return "Post Advert";

    case PROTECTED_PATHS.EDIT_SHOP_PRODUCT:
      return "Edit Advert";

    case PROTECTED_PATHS.CABLE_TV:
      return "Cable Tv";

    case PROTECTED_PATHS.POWER_UTILITY:
      return "Power Utility";

    case PROTECTED_PATHS.OFFER_JOINT_ONE:
      return "Offer";
    case PROTECTED_PATHS.ALL_OFFERS:
      return "All Offers";

    case `${PROTECTED_PATHS.CATEGORY}/drinks`:
      return "Category";

    case PROTECTED_PATHS.AIRTEL_AIRTIME:
      return "Airtime Purchase";

    case PROTECTED_PATHS.MTN_AIRTIME:
      return "Airtime Purchase";

    case PROTECTED_PATHS.GLO_AIRTIME:
      return "Airtime Purchase";

    case PROTECTED_PATHS.ETISALAT_AIRTIME:
      return "Airtime Purchase";

    case PROTECTED_PATHS.MTN_DATA:
      return "Data Purchase";

    case PROTECTED_PATHS.AIRTEL_DATA:
      return "Data Purchase";

    case PROTECTED_PATHS.ETISALAT_DATA:
      return "Data Purchase";

    case PROTECTED_PATHS.GLO_DATA:
      return "Data Purchase";

    case PROTECTED_PATHS.SPECIAL_GUEST_REQUEST:
      return "Special Request";

    case PROTECTED_PATHS.CONTRACTOR_ARTISAN:
      return "Contractor and Artisan";

    case PROTECTED_PATHS.VEHICLES:
      return "Vehicles";

    case PROTECTED_PATHS.PARTY_EVENT:
      return "Party Event";

    case PROTECTED_PATHS.PARTY_EVENT_REQUEST:
      return "Party Event Request";

    case PROTECTED_PATHS.SHOP:
      return "My Shop";

    case PROTECTED_PATHS.POLLS:
      return "Polls";

    case `${PROTECTED_PATHS.POLLS}/vote/voteID`:
      return "Vote";

    case PROTECTED_PATHS.SERVICES:
      return "Services";

    case PROTECTED_PATHS.SETTINGS:
      return "Settings";

    case PROTECTED_PATHS.ID_CARD:
      return "ID Card";

    case PROTECTED_PATHS.REQUEST_ID:
      return "Request ID Card";

    case PROTECTED_PATHS.ALL_TRANSACTIONS:
      return "Transactions";

    case PROTECTED_PATHS.TRANSACTIONS_Details:
      return "Transaction Details";

    case PROTECTED_PATHS.ACCOUNT_STATEMENT:
      return "Account Statement";

    case PROTECTED_PATHS.PENDING_BILLS:
      return "Pending Bills";

    case PROTECTED_PATHS.ESTATE_BILLS:
      return "Estate Bills";

    case PROTECTED_PATHS.EVENT_LIST:
      return "Event List";

    case PROTECTED_PATHS.CONTRACTOR_LIST:
      return "Contractor List";

    case PROTECTED_PATHS.VEHICLE_LIST:
      return "Vehicle List";

    case PROTECTED_PATHS.CHAT:
      return "Chat with MyEstateNG";

    default:
      return " ";
  }
};
