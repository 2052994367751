import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getFamily = async (userId, setLoading, setFamily) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_FAMILY(userId));
    setFamily(data?.families);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};
export const getCard = async (phone, setLoading, setCard) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CARD(phone));
    setCard(data?.cards);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

// export const requestCard = async (
//   payload,
//   setBtnLoading,
//   setPayStackModalOpen
// ) => {
//   setBtnLoading(true);
//   try {
//     await http.post(AUTH_ROUTES.POST_CARD, payload);
//     setPayStackModalOpen(true);
//   } catch (err) {
//     console.log(err.response);
//     errorNotifier();
//   } finally {
//     setBtnLoading(false);
//   }
// };

export const requestCard =
  (payload) =>
  (setLoading) =>
  (setPaymentModal) =>
  (navigate) =>
  async (setPaymentData) => {
    try {
      const {
        data: { data },
      } = await http.post(AUTH_ROUTES.POST_CARD, payload);
      // successNotifier("Wallet refunded Successfully");
      setPaymentModal(true);
      // setLoading(false);
      setPaymentData(data);

      // redirect to index page
      navigate("/IdCard");
    } catch (e) {
      errorNotifier(
        e.response?.data?.message ||
          e.response?.data?.data?.message ||
          e?.response?.data?.result?.message
      );
    } finally {
      setLoading(false);
      // setRefresh((prev) => !prev);
    }
  };
