import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { COLORS } from "../../../constants/colors";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { transition } from "../../..";
import { PROTECTED_PATHS } from "../../../app/constants";
import { getAllTransactions } from "../service";
import { AuthContext } from "../../../context/authContext";
import dayjs from "dayjs";
import logo from "../logo.svg";
import { useLocation } from "react-router-dom";

export const TransactionDetails = () => {
  const [allTransactions, setAllTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState();
  const [showButtons, setShowButtons] = useState(true);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;

  const cardBg = useColorModeValue(COLORS.black, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const { ALL_TRANSACTIONS } = PROTECTED_PATHS;

  useEffect(() => {
    getAllTransactions(userId, setLoading, setAllTransactions);
  }, [userId]);

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handlePrint = () => {
    setShowButtons(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  const location = useLocation();

  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Box pb={"5vh"}>
        <Stack display={showButtons === false ? "none" : "block"}>
          <Box shadow="sm">
            <Container>
              <Header goto={ALL_TRANSACTIONS} />
            </Container>
          </Box>
        </Stack>
        <Container>
          <Box color={bgColor} textAlign={"center"} pt={"30px"}>
            <Flex justify={"center"} mt={"20px"}>
              <HStack>
                <Image src={logo} alt="logo" color={"black"} />
                <Text color={cardBg} fontWeight={"light"}>
                  MyEstate
                </Text>
              </HStack>
            </Flex>
            <Text fontSize={"20px"} pb={"40px"} pt={"20px"} color={cardBg}>
              Transaction Details
            </Text>
            {location?.state && (
              <Stack spacing={4} color={cardBg} px={"10px"}>
                <Flex justify={"space-between"}>
                  <Text>Account Name:</Text>
                  <Text>{location?.state?.userId?.accountName}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Account Number:</Text>
                  <Text>{location?.state?.userId?.accountNo}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Bank:</Text>
                  <Text>{location?.state?.userId?.bank}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Email:</Text>
                  <Text>{location?.state?.userId?.email}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Phone Number:</Text>
                  <Text>{location?.state?.userId?.tenantPhone}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Service:</Text>
                  <Text>{location?.state?.services}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Transaction Type:</Text>
                  <Text>{location?.state?.transactionType}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Transaction Ref:</Text>
                  <Text>{location?.state?.transactionRef}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Transaction Channel:</Text>
                  <Text>{location?.state?.transactionChannel}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Status:</Text>
                  <Text>{location?.state?.status}</Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Date:</Text>
                  <Text>
                    {dayjs(location?.state?.updatedAt).format(
                      " h:mm A MMM D, YYYY"
                    )}
                  </Text>
                </Flex>
                <Flex justify={"space-between"}>
                  <Text>Amount:</Text>
                  <Text>
                    &#8358; {location?.state?.amount?.toLocaleString()}
                  </Text>
                </Flex>
              </Stack>
            )}
            <Flex
              mt={"50px"}
              align={"center"}
              justify={"space-between"}
              px={"10px"}
            >
              <Text mb={"-50px"} color={cardBg}>
                Powered by MyEstate.ng
              </Text>
              {showButtons && (
                <Button
                  _hover={{ color: `${cardBg}` }}
                  bg={COLORS.green}
                  color={cardBg}
                  onClick={handlePrint}
                >
                  Print
                </Button>
              )}
            </Flex>
          </Box>
        </Container>
      </Box>
    </SlideFade>
  );
};
